<template>
    <transition name="fade">
    <div v-if="!state.loading">  

    <div
      class="fade show"
      id="kt_modal_add_event"
      aria-modal="true"
      role="dialog"
      ref="newTargetModalRef"
    >
      <div class="p-0 m-0 card mb-5 mb-xxl-8" >
        <div class="card-header pb-0">
            <div class="card-title">
              <div class="card-title">
                <i @click="handleCloseDrawer(ValidConfirm)" class="bi bi-arrow-left-short fs-2tx text-primary text-hover-gray-600 cursor-pointer me-4"></i>
                <div class="fs-4 text-uppercase text-gray-800">{{getTitle()}}</div>
              </div>
            </div>
            <div class="my-4 mt-7" >
              <el-popconfirm v-if="props.indispoId && props.indispoId > 0 && state.targetData.li_status == 'En attente de validation'" title="Supprimer cette indisponibilité ?" @confirm="deleteIndispo()"  :confirm-button-text="$t('Oui')" :cancel-button-text="$t('Non')"  icon-color="danger">
                <template #reference>
                  <i class="bi bi-trash3 fs-1 text-danger text-hover-gray-600 cursor-pointer me-4" ></i>
                </template>
              </el-popconfirm>
              <i @click="submit()" v-if="state.targetData.li_status == 'En attente de validation' || !state.targetData.li_status" class="px-5 bg-success py-1 bi bi-check-circle-fill fs-1 text-white text-hover-gray-600 cursor-pointer me-4 my-3" style="border-radius: 5px" ></i>
            </div>
        </div>
        </div>
          <el-form
            class="form fv-plugins-bootstrap5 fv-plugins-framework"
            @submit.prevent="submit()"
            :model="state.targetData"
            ref="formRef"
          >
            <!--begin::Modal body-->
            <div class="py-10">
              <div class="card mb-5 mb-xxl-8 mx-4 mx-md-8" v-if="state.targetData.li_status == 'En attente de validation' && state.allRest.length == 0">
                <div class="card-body pt-2 pt-md-5 pb-0 row mb-5">
                  <div class="row mb-5">
                    <label class="col-lg-4 col-form-label fw-semobold fs-6">Validation de l'indisponibilité</label>
                    <div class="col-lg-8 col-12 fv-row d-flex" >
                        <el-popconfirm title="Accepter cette indisponibilité ?" @confirm="workflowIndispo(1)"  :confirm-button-text="$t('Oui')" :cancel-button-text="$t('Non')"  icon-color="danger">
                          <template #reference>
                            <button type="button" class="btn btn-success me-2 w-100">
                              <i class="bi bi-check2 fs-1 text-white text-hover-gray-600 cursor-pointer me-4" ></i>
                              Accepter
                            </button>
                          </template>
                        </el-popconfirm>
                        <el-popconfirm title="Refuser cette indisponibilité ?" @confirm="workflowIndispo(2)"  :confirm-button-text="$t('Oui')" :cancel-button-text="$t('Non')"  icon-color="danger">
                          <template #reference>
                            <button type="button" class="btn btn-danger me-2 w-100">
                              <i class="bi bi-file-earmark-x fs-2 text-white text-hover-gray-600 cursor-pointer me-4" ></i>
                              Refuser
                            </button>
                          </template>
                        </el-popconfirm>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-5 mb-xxl-8 mx-4 mx-md-8" v-if="props.seeAll && state.targetData.li_status">
                <div class="card-body pt-2 pt-md-5 pb-0 row">
                  <div class="row mb-5">
                    <label class="col-lg-4 col-form-label fw-semobold fs-6">Concernant</label>
                    <div class="col-lg-8 col-12 fv-row">
                      <input type="text" class="form-control form-control-solid rounded-3" disabled :value="state.targetData.li_personne">
                    </div>
                  </div> 
                </div>
              </div>
              <!--begin::card-->
              <div class="card mb-5 mb-xxl-8 mx-4 mx-md-8" >
                <div class="card-body pt-2 pt-md-5 pb-0 row mb-5">
                  <div class="row mb-5">
                    <label class="col-lg-4 col-form-label fw-semobold fs-6 required">Type</label>
                    <div class="col-lg-8 col-12 fv-row d-flex">
                      <select v-model="state.targetData.li_knum_indispo" name="status" :disabled="checkEditable()" data-control="select2" data-hide-search="true" class="form-select form-select fw-bolder " >
                        <option v-for="item, index in state.indispoTypes" :key="index" :value="item.ti_seq" :label="item.ti_libelle" name="type" class="mb-3"/>
                      </select>
                    </div>
                  </div>   
                  <div class="row mb-5">
                    <label class="col-lg-4 col-form-label fw-semobold fs-6">Description</label>
                    <div class="col-lg-8 col-12 fv-row">
                        <textarea v-model="state.targetData.li_notes" :disabled="checkEditable()" class="form-control form-control-solid rounded-3" rows="4"/>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::card-->
              <!--begin::card-->
              <div class="card mb-5 mb-xxl-8 mx-4 mx-md-8" >
                <div class="card-body pt-2 pt-md-5 pb-0 row mb-5">
                  <div class="row mb-5">
                    <label class="col-lg-4 col-form-label fw-semobold fs-6 required">Date de début</label>
                    <div class="col-lg-8 col-12 fv-row" style="flex-direction: row;display: flex;align-content: center;flex-wrap: nowrap;align-items: center;">
                        <input v-model="state.targetData.li_date_deb" type="date" :disabled="checkEditable()" class="form-control ps-4 w-150px" />
                        <input type="radio" name="debut" checked class="form-check-input min-w-10px min-h-10px ms-3 me-1" v-model="state.targetData.demiStart" :value="false" :disabled="checkEditable()"><span class="form-check-label" > Matin </span>
                        <input type="radio" name="debut" class="form-check-input min-w-10px min-h-10px ms-3 me-1" v-model="state.targetData.demiStart" :value="true" :disabled="checkEditable()"><span class="form-check-label" > Après&#8209;midi </span>
                    </div>
                  </div> 
                  <div class="row mb-5">
                    <label class="col-lg-4 col-form-label fw-semobold fs-6 required">Date de fin</label>
                    <div class="col-lg-8 col-12 fv-row" style="flex-direction: row;display: flex;align-content: center;flex-wrap: nowrap;align-items: center;">                      
                      <input v-model="state.targetData.li_date_fin" type="date" :disabled="checkEditable()" class="form-control ps-4 w-150px" />
                      <input type="radio" name="fin" checked class="form-check-input min-w-10px min-h-10px ms-3 me-1" v-model="state.targetData.demiEnd" :value="false" :disabled="checkEditable()"><span class="form-check-label"> Matin </span>
                      <input type="radio" name="fin" class="form-check-input min-w-10px min-h-10px ms-3 me-1" v-model="state.targetData.demiEnd" :value="true" :disabled="checkEditable()"><span class="form-check-label"> Après&#8209;midi </span> 
                    </div>
                  </div>
                </div>
              </div>
              <!--end::card-->
            </div>
            <!--end::Modal body-->
            <div></div>
          </el-form>
          <!--end::Form-->
        </div>
      </div>
    </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, defineExpose } from "vue";
import mAxiosApi from "@/api";
import { ElMessageBox, ElNotification } from "element-plus";

export default defineComponent({
  name: "addIndispo",
  components: {
  },
  props: {
    indispoId: Number,
    seeAll: Number
  },
  setup(props, { emit }) {

    const state = reactive({
      indispoTypes: Array,
      beforeChange: String as any,
      allRest: {} as any,
      loading : true,
      targetData: {
        li_notes: "",
        li_status: "",
        li_date_deb: "",
        li_date_fin: "",
        li_personne: "",
        li_seq : props.indispoId,
        li_knum_indispo : 0,
        li_knum_entite: 0,
        demiStart: false,
        demiEnd: false,
      }
    });

    const rules = ref({
      type: [
        {
          required: true,
          min: 0,
          message: "Rentrez le type",
          trigger: "blur",
        },
      ],
    });

    const errotNotif = (message) => {
      ElNotification({title: 'Erreur', offset: 70, message: message, type: 'error', duration: 3000 })
    }

    const gardeFou = (fullDateDeb, fullDateFin) => {
      if (state.targetData.li_date_deb.length == 0 || state.targetData.li_date_fin.length == 0) {
        errotNotif("Les dates sont obligatoires !");
        return true;
      }
      if (new Date(fullDateDeb) >= new Date(fullDateFin)) {
        errotNotif("Veuillez vérifier les dates saisies !");
        return true;
      }
      if (!state.targetData.li_knum_indispo) {
        errotNotif("Aucun type d'indisponibilité sélectionné");
        return true;
      }
      /*
      if (new Date(state.targetData.li_date_fin) <  new Date() || new Date(state.targetData.li_date_fin) <  new Date() ) {=
        ElMessageBox.confirm('Il semble qu\'une date soit inférieur à aujourd\'hui', 'Attention', {
          confirmButtonText: 'C\'est normal, continuer',
          cancelButtonText: 'Annuler',
        })
        .then(() => { return false; })
        .catch(() => { return true; })
      }else {
        return false;
      }
      */
    }

    const submit = () => {
        const fullDateDeb = state.targetData.li_date_deb + (state.targetData.demiStart ? ' 12:00:00' : ' 00:00:00');
        let fullDateFin = state.targetData.li_date_fin + (state.targetData.demiEnd ? ' 23:59:59' : ' 12:00:00');

        if (gardeFou(fullDateDeb, fullDateFin)) return false;

        const zFullDateFin = new Date(fullDateFin + " UTC");
        zFullDateFin.setSeconds(zFullDateFin.getSeconds() + 1);
        fullDateFin = zFullDateFin.toISOString().slice(0, 19).replace("T", " ");

        state.targetData.li_date_deb = fullDateDeb;
        state.targetData.li_date_fin = fullDateFin;
        const isCreate = state.targetData.li_status ? false : true;
        mAxiosApi.post("/addIndispo", state.targetData)
          .then(() => {
            emit('setCalendarDatas');
            ElNotification({
              title: 'Succès',
              message: isCreate ? 'Indisponibilité créée !' : 'Indisponibilité modifiée !',
              type: 'success',
              duration: 3000
            });
            emit('closeDrawer');
          })
    }

    const deleteIndispo = () => {
      mAxiosApi.post("/deleteIndispo/" + state.targetData.li_seq)
      emit('setCalendarDatas')
      ElNotification({
        title: 'Succès',
        message: 'Indisponibilité supprimée !',
        type: 'success',
        duration: 3000
      })
      emit('closeDrawer')
    }

    const getTitle = () =>{
      if (props.indispoId as any > 0 && state.targetData.li_status == 'En attente de validation') {
        return 'Modification'
      }else if(checkEditable()){
        return 'Visualiser'
      }else{
        return 'Nouvelle indispo'
      }
    }

    const checkEditable = () => {
      return state.targetData.li_status !== 'En attente de validation' && props.indispoId as any > 0
    }

    const handleCloseDrawer = (done: () => void) => {
      if (state.beforeChange === JSON.stringify(state.targetData)){
        done();
        return false;
      }
      ElMessageBox({
          title: 'Confirmation', 
          message :'Vos données non sauvegardées seront perdues, continuer ?',  
          showCancelButton: true,
          customClass: "responsiveMessageBox",
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler'})
        .then(() => {
          done && done();
        })
    }

    const ValidConfirm = () => {
      emit('closeDrawer')
    };

    onMounted(async() => {

      const allRest = await mAxiosApi.prototype.getAxios("/getPermissions");
      state.allRest =  allRest.results.filter(
          (item: any) =>item.per_code_permission == 'ERP_TRAITE_INDISPO'
      );

      const types = await mAxiosApi.prototype.getAxios("/getIndispoTypes");
      state.indispoTypes = types.results;

      if (props.indispoId as any > 0) {

        const indispo = await mAxiosApi.prototype.getAxios(props.seeAll ? "/getIndispo/1/"+ props.indispoId : "/getIndispo/0/"+ props.indispoId);
        const results = indispo.results[0];

        if (results?.li_date_fin.endsWith(" 00:00:00")) {
          const dateObj = new Date(results?.li_date_fin);
          dateObj.setSeconds(dateObj.getSeconds() - 1);
          results.li_date_fin = dateObj.toISOString().slice(0, 19).replace("T", " ");
        }

        state.targetData = {
          li_seq : results?.li_seq,
          li_knum_indispo : results?.li_knum_indispo,
          li_knum_entite: results?.li_knum_entite,
          li_personne: results?.li_personne,
          li_notes: results?.li_notes,
          li_status: results?.li_status,
          li_date_deb: results?.li_date_deb.substr(0, 10),  
          li_date_fin: results?.li_date_fin.substr(0, 10),
          demiStart: results?.li_date_deb.substr(11, 2) === "12",
          demiEnd: results?.li_date_fin.substr(11, 2) !== "12",
        }

      }
      state.beforeChange = JSON.stringify(state.targetData)
      state.loading = false;
    })

    const workflowIndispo = (status) => {
      const rIndispo = {
        li_seq: state.targetData.li_seq,
        li_status : status
      };

      mAxiosApi.post("/workflowIndispo", rIndispo)
      .then(() => {
        emit('setCalendarDatas');
        ValidConfirm();
      })
    }

    defineExpose({
      handleCloseDrawer,
      ValidConfirm
    })

    return {
      state,
      props,
      rules,
      submit,
      emit,
      getTitle,
      checkEditable,
      handleCloseDrawer,
      ValidConfirm,
      workflowIndispo,
      deleteIndispo
    };
  },
});
</script>

<style lang="scss">
.el-select {
  width: 100%;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>
