
  import * as moment from 'moment'
  import { defineComponent, reactive, ref } from "vue";
  import SearchAndFilter from "@/views/still/search_and_filters.vue";

  export default defineComponent({
    name: "indispo-list",
    components: { SearchAndFilter },
    props: {
      calendarDatas: Object,
      seeAll: String
    },
    emit: ["openDrawer"],
    setup(props, { emit }) {

      const state = reactive({
        calendarDatas: props.calendarDatas,
        seeAll: props.seeAll,
        initialMyList: props.calendarDatas as any,
        loadingDatatable : 0,
        loading : false,
      })

      const formatDate = (d) => {
        return moment.default(d).format("DD.MM.YY");
      };

      const formatHour = (d) => {
        return moment.default(d).format('HH:mm')
      }

      const getBadgeIcon = (status) => {
        if (status === "Accepté") {
          return 'badge-light-success'
        }
        if (status === "En attente de validation") {
          return 'badge-light-warning'
        }
        if (status === "Refusé") {
          return 'badge-light-danger'
        }
      }

      const getTdWidth = () =>{
        return window.matchMedia('(min-width: 768px)').matches ? '150px' : ''
      }

      const tableFilters = ref([]) as any;
      tableFilters.value.push({ name: "Type", key: "ti_libelle", label: "ti_libelle" });
      if (props.seeAll == "2") {
        tableFilters.value.push({ name: "Statut", key: "li_status", label: "li_status", default : ["En attente de validation"] });
      } else {
        tableFilters.value.push({ name: "Statut", key: "li_status", label: "li_status"});
      } 
      if (props.seeAll) tableFilters.value.push({ name: "Collaborateur", key: "li_personne", label: "li_personne" });

      state.loading = false;

      return{
        state,
        formatDate,
        getBadgeIcon,
        formatHour,
        emit,
        getTdWidth,
        tableFilters
      }
    }
  });
