<template>

<transition name="fade">
    <div v-if="!state.loading">

        <!--begin::Body-->
        <div class="card shadow-none d-none d-md-block">
          <div class="card-header border-0 p-0">
            <SearchAndFilter
                :listInitial="state.initialMyList"
                :filters="tableFilters"
                :key="tableFilters"
                classSearch="h-40px"
                :widthDecalage="`${$props.seeAll ? '-380' : '-300'}`"
                @returnSearch="state.calendarDatas = $event"
                @returnLoadingDatatable="state.loadingDatatable = $event"
                classSelect="w-md-200px mx-1"
            ></SearchAndFilter> 
        </div>
      </div>

        <div class="card-body py-3  text-start p-0">
          <div class="tab-content">
            <!--begin::Tap pane-->
            <div class="tab-pane fade active show" id="kt_table_widget_5_tab_1">
              <!--begin::Table container-->
              <div class="table-responsive">
                <!--begin::Table-->
                <table
                  class="
                    table table-row-dashed table-row-gray-200
                    align-middle
                    gs-0
                    gy-4
                  "
                >
                  <!--begin::Table head-->
                  <thead>
                    <tr class="border-0">
                      <th class="p-0"></th>
                      <th class="p-0" v-if="state.seeAll"></th>
                      <th class="p-0"></th>
                      <th class="p-0"></th>
                    </tr>
                  </thead>
                  <!--end::Table head-->
                  <!--begin::Table body-->
                  <tbody>
                    <tr v-for="item, index in state.calendarDatas" :key="index" class="h-70px bg-hover-light-primary" @click="emit('openDrawer', item.li_seq);">
                      <td class="fw-bold text-left fs-7 d-table-cell d-md-none p-2 m-10" width="100%">
                        <div class="p-2">
                          <span class="fs-8 badge" :class="getBadgeIcon(item.li_status)" >
                            {{ item.li_status }}
                          </span>
                          <div v-if="state.seeAll" class="text-primary fw-plain mt-1">{{item.li_personne}}<br></div>
                          <div class="mt-2 ms-2">
                            <span v-html="item.TXTDU"></span> <span v-html="item.TXTAU"></span><br>
                            <span class="text-muted fw-bold fs-7">Soit <b>{{parseFloat(item.nb_jr)}}</b> jours</span>
                          </div>
                          <div class="text-dark fw-bolder fs-7 ms-2 mt-2">
                            {{ item.ti_libelle }}
                            <span class="text-muted fw-bold d-block fs-7">{{item.li_notes}}</span>
                          </div>
                        </div>
                      </td>
                      <td class="fw-bold text-left fs-7  d-none d-md-table-cell" width="150px">
                        <span v-html="item.TXTDU"></span><br/>
                        <span v-html="item.TXTAU"></span>
                        <span class="text-muted fw-bold d-block fs-7">soit <b>{{parseFloat(item.nb_jr)}}</b> jours</span>
                      </td>
                      <td class="text-dark fw-bolder mb-1 fs-6 mw-200px d-none d-md-table-cell">
                        {{ item.ti_libelle }}
                        <span class="text-muted fw-bold d-block fs-6">{{item.li_notes}}</span>
                      </td>
                      <td class="fw-bold d-none d-md-table-cell" v-if="state.seeAll">
                        {{item.li_personne}}
                      </td>
                      <td class="text-end d-none d-md-table-cell">
                        <span class="badge fs-7 me-2" :class="getBadgeIcon(item.li_status)"
                          v-html="`${item.li_status === 'En attente de validation' ? 'En attente<br>de validation' : item.li_status }`">
                        </span>
                      </td>
                    </tr>
                  </tbody>
                  <!--end::Table body-->
                </table>
                <el-empty description="Aucune indisponibilité" v-if="state.calendarDatas.length == 0 | !state.calendarDatas"/>
              </div>
              <!--end::Table-->
            </div>
            <!--end::Tap pane-->
          </div>
        </div>
      </div>
  </transition>
</template>

<script lang="ts">
  import * as moment from 'moment'
  import { defineComponent, reactive, ref } from "vue";
  import SearchAndFilter from "@/views/still/search_and_filters.vue";

  export default defineComponent({
    name: "indispo-list",
    components: { SearchAndFilter },
    props: {
      calendarDatas: Object,
      seeAll: String
    },
    emit: ["openDrawer"],
    setup(props, { emit }) {

      const state = reactive({
        calendarDatas: props.calendarDatas,
        seeAll: props.seeAll,
        initialMyList: props.calendarDatas as any,
        loadingDatatable : 0,
        loading : false,
      })

      const formatDate = (d) => {
        return moment.default(d).format("DD.MM.YY");
      };

      const formatHour = (d) => {
        return moment.default(d).format('HH:mm')
      }

      const getBadgeIcon = (status) => {
        if (status === "Accepté") {
          return 'badge-light-success'
        }
        if (status === "En attente de validation") {
          return 'badge-light-warning'
        }
        if (status === "Refusé") {
          return 'badge-light-danger'
        }
      }

      const getTdWidth = () =>{
        return window.matchMedia('(min-width: 768px)').matches ? '150px' : ''
      }

      const tableFilters = ref([]) as any;
      tableFilters.value.push({ name: "Type", key: "ti_libelle", label: "ti_libelle" });
      if (props.seeAll == "2") {
        tableFilters.value.push({ name: "Statut", key: "li_status", label: "li_status", default : ["En attente de validation"] });
      } else {
        tableFilters.value.push({ name: "Statut", key: "li_status", label: "li_status"});
      } 
      if (props.seeAll) tableFilters.value.push({ name: "Collaborateur", key: "li_personne", label: "li_personne" });

      state.loading = false;

      return{
        state,
        formatDate,
        getBadgeIcon,
        formatHour,
        emit,
        getTdWidth,
        tableFilters
      }
    }
  });
</script>