
import { defineComponent, onMounted, reactive, ref, defineExpose } from "vue";
import mAxiosApi from "@/api";
import { ElMessageBox, ElNotification } from "element-plus";

export default defineComponent({
  name: "addIndispo",
  components: {
  },
  props: {
    indispoId: Number,
    seeAll: Number
  },
  setup(props, { emit }) {

    const state = reactive({
      indispoTypes: Array,
      beforeChange: String as any,
      allRest: {} as any,
      loading : true,
      targetData: {
        li_notes: "",
        li_status: "",
        li_date_deb: "",
        li_date_fin: "",
        li_personne: "",
        li_seq : props.indispoId,
        li_knum_indispo : 0,
        li_knum_entite: 0,
        demiStart: false,
        demiEnd: false,
      }
    });

    const rules = ref({
      type: [
        {
          required: true,
          min: 0,
          message: "Rentrez le type",
          trigger: "blur",
        },
      ],
    });

    const errotNotif = (message) => {
      ElNotification({title: 'Erreur', offset: 70, message: message, type: 'error', duration: 3000 })
    }

    const gardeFou = (fullDateDeb, fullDateFin) => {
      if (state.targetData.li_date_deb.length == 0 || state.targetData.li_date_fin.length == 0) {
        errotNotif("Les dates sont obligatoires !");
        return true;
      }
      if (new Date(fullDateDeb) >= new Date(fullDateFin)) {
        errotNotif("Veuillez vérifier les dates saisies !");
        return true;
      }
      if (!state.targetData.li_knum_indispo) {
        errotNotif("Aucun type d'indisponibilité sélectionné");
        return true;
      }
      /*
      if (new Date(state.targetData.li_date_fin) <  new Date() || new Date(state.targetData.li_date_fin) <  new Date() ) {=
        ElMessageBox.confirm('Il semble qu\'une date soit inférieur à aujourd\'hui', 'Attention', {
          confirmButtonText: 'C\'est normal, continuer',
          cancelButtonText: 'Annuler',
        })
        .then(() => { return false; })
        .catch(() => { return true; })
      }else {
        return false;
      }
      */
    }

    const submit = () => {
        const fullDateDeb = state.targetData.li_date_deb + (state.targetData.demiStart ? ' 12:00:00' : ' 00:00:00');
        let fullDateFin = state.targetData.li_date_fin + (state.targetData.demiEnd ? ' 23:59:59' : ' 12:00:00');

        if (gardeFou(fullDateDeb, fullDateFin)) return false;

        const zFullDateFin = new Date(fullDateFin + " UTC");
        zFullDateFin.setSeconds(zFullDateFin.getSeconds() + 1);
        fullDateFin = zFullDateFin.toISOString().slice(0, 19).replace("T", " ");

        state.targetData.li_date_deb = fullDateDeb;
        state.targetData.li_date_fin = fullDateFin;
        const isCreate = state.targetData.li_status ? false : true;
        mAxiosApi.post("/addIndispo", state.targetData)
          .then(() => {
            emit('setCalendarDatas');
            ElNotification({
              title: 'Succès',
              message: isCreate ? 'Indisponibilité créée !' : 'Indisponibilité modifiée !',
              type: 'success',
              duration: 3000
            });
            emit('closeDrawer');
          })
    }

    const deleteIndispo = () => {
      mAxiosApi.post("/deleteIndispo/" + state.targetData.li_seq)
      emit('setCalendarDatas')
      ElNotification({
        title: 'Succès',
        message: 'Indisponibilité supprimée !',
        type: 'success',
        duration: 3000
      })
      emit('closeDrawer')
    }

    const getTitle = () =>{
      if (props.indispoId as any > 0 && state.targetData.li_status == 'En attente de validation') {
        return 'Modification'
      }else if(checkEditable()){
        return 'Visualiser'
      }else{
        return 'Nouvelle indispo'
      }
    }

    const checkEditable = () => {
      return state.targetData.li_status !== 'En attente de validation' && props.indispoId as any > 0
    }

    const handleCloseDrawer = (done: () => void) => {
      if (state.beforeChange === JSON.stringify(state.targetData)){
        done();
        return false;
      }
      ElMessageBox({
          title: 'Confirmation', 
          message :'Vos données non sauvegardées seront perdues, continuer ?',  
          showCancelButton: true,
          customClass: "responsiveMessageBox",
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler'})
        .then(() => {
          done && done();
        })
    }

    const ValidConfirm = () => {
      emit('closeDrawer')
    };

    onMounted(async() => {

      const allRest = await mAxiosApi.prototype.getAxios("/getPermissions");
      state.allRest =  allRest.results.filter(
          (item: any) =>item.per_code_permission == 'ERP_TRAITE_INDISPO'
      );

      const types = await mAxiosApi.prototype.getAxios("/getIndispoTypes");
      state.indispoTypes = types.results;

      if (props.indispoId as any > 0) {

        const indispo = await mAxiosApi.prototype.getAxios(props.seeAll ? "/getIndispo/1/"+ props.indispoId : "/getIndispo/0/"+ props.indispoId);
        const results = indispo.results[0];

        if (results?.li_date_fin.endsWith(" 00:00:00")) {
          const dateObj = new Date(results?.li_date_fin);
          dateObj.setSeconds(dateObj.getSeconds() - 1);
          results.li_date_fin = dateObj.toISOString().slice(0, 19).replace("T", " ");
        }

        state.targetData = {
          li_seq : results?.li_seq,
          li_knum_indispo : results?.li_knum_indispo,
          li_knum_entite: results?.li_knum_entite,
          li_personne: results?.li_personne,
          li_notes: results?.li_notes,
          li_status: results?.li_status,
          li_date_deb: results?.li_date_deb.substr(0, 10),  
          li_date_fin: results?.li_date_fin.substr(0, 10),
          demiStart: results?.li_date_deb.substr(11, 2) === "12",
          demiEnd: results?.li_date_fin.substr(11, 2) !== "12",
        }

      }
      state.beforeChange = JSON.stringify(state.targetData)
      state.loading = false;
    })

    const workflowIndispo = (status) => {
      const rIndispo = {
        li_seq: state.targetData.li_seq,
        li_status : status
      };

      mAxiosApi.post("/workflowIndispo", rIndispo)
      .then(() => {
        emit('setCalendarDatas');
        ValidConfirm();
      })
    }

    defineExpose({
      handleCloseDrawer,
      ValidConfirm
    })

    return {
      state,
      props,
      rules,
      submit,
      emit,
      getTitle,
      checkEditable,
      handleCloseDrawer,
      ValidConfirm,
      workflowIndispo,
      deleteIndispo
    };
  },
});
