
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted, reactive, ref} from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from '@fullcalendar/multimonth';
import { TODAY } from "@/core/data/events";
import { Modal } from "bootstrap";
import AddIndispoVue from "./AddIndispo.vue";
import frLocale from '@fullcalendar/core/locales/fr';
import IndisposListVue from "./IndisposList.vue";
import mAxiosApi from "@/api";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "Indispos",
  components: {
    FullCalendar,
    AddIndispoVue,
    IndisposListVue
  },
  setup() {
    setCurrentPageBreadcrumbs("Indisponibilités", ["Pages", "Profile"]);
    
    const calendarRef = ref(null) as any;
    const router = useRouter()
    const state = reactive({
      drawerAdd: false,
      radio: "Liste",
      emptyView: true,
      renderAdd: 0,
      renderList: 0,
      indispoId: 0,
      seeAll: "" as any,
      calendarDatas: [] as any,
      loading: true,
    })

    const setCalendarDatas = async () => {
            
      let indispoData
      if (state.seeAll) {
        indispoData = await mAxiosApi.prototype.getAxios('/getIndispo/1');
      }else{
        indispoData = await mAxiosApi.prototype.getAxios('/getIndispo/0');
      }
      indispoData = indispoData.results.sort((el, el2) => {
        const date1 = new Date(el.li_date_deb) as any;
        const date2 = new Date(el2.li_date_deb) as any;
        return date2 - date1;
      })

      indispoData.forEach(element => {
        element.start = new Date(element.li_date_deb);
        element.end = new Date(element.li_date_fin);
        element.title = state.seeAll ? element.li_personne + ' / ' + element.ti_libelle : element.ti_libelle;

        const debDate = new Date(element.li_date_deb)
        const finDate = new Date(element.li_date_fin)
        if (debDate.getHours() !== 12 || debDate.getMinutes() !== 0) {
          element.TXTDU = 'Du <b>' + formatDate(debDate) + '</b> matin';
        } else {
          element.TXTDU = 'Du <b>' + formatDate(debDate) + '</b> après-midi';
        }

        if (element.li_date_fin) {
          if (finDate.getHours() !== 12 || finDate.getMinutes() !== 0) {
            element.TXTAU = 'au <b>' + formatDate(finDate, -1) + '</b> soir';
          } else {
            element.TXTAU = 'au <b>' + formatDate(finDate) + '</b> midi';
          }
        } else {
          element.TXTAU = '';
        }
        
      });

      state.calendarDatas = indispoData;
      state.loading = false;
      
      state.renderList = state.renderList + 1;
      state.renderAdd = state.renderAdd + 1;
      (calendarRef.value as any).calendar.getEventSources().forEach(element => {
        element.remove();
      });
      (calendarRef.value as any).calendar.addEventSource(state.calendarDatas);

      

    }

    const showIndispo = (indispo) => {
      openEditDrawer(indispo.event.extendedProps.li_seq)
    };

    const calendarOptions = {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, multiMonthPlugin],
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "multiMonthYear,dayGridMonth,timeGridWeek",
      },
      initialDate: TODAY,
      initialView: "emptyView",
      navLinks: true, // can click day/week names to navigate views
      selectable: true,
      selectMirror: true,
      locale: frLocale,
      views: (window.matchMedia('(max-width: 768px)').matches) ? {
        dayGridMonth: {},
        timeGridWeek: {},
        multiMonthYear: {},
        emptyView: {
          type: 'dayGrid', 
          content: function() {
            let el = document.createElement('div')
            el.innerHTML = ''
            return { domNodes: [el] }
          }
        }
      } : 
      {
        dayGridMonth: { buttonText: "Mois" },
        timeGridWeek: { buttonText: "Semaine" },
        multiMonthYear: { buttonText: "Année"},
        emptyView: {
          type: 'dayGrid', 
          content: function() {
            let el = document.createElement('div')
            el.innerHTML = ''
            return { domNodes: [el] }
          }
        }
      },
      contentHeight:"auto",
      editable: false,
      events: state.calendarDatas,
      dayMaxEvents: true, // allow "more" link when too many events
      eventClick: indispo => {showIndispo(indispo)},
    };

    const changeCalendarView = view => {
      if (view === "emptyView") {
        state.emptyView = true;
      }else{
        state.emptyView = false
      }
      (calendarRef.value as any).calendar.getEventSources().forEach(element => {
        element.remove();
      });
      (calendarRef.value as any).calendar.addEventSource(state.calendarDatas);
      (calendarRef.value as any).calendar.changeView(view);
    }

    const ValidConfirm = () => {
      state.drawerAdd = false;
    };

    const openEditDrawer = (id) => {
      state.indispoId = parseInt(id);
      state.drawerAdd = true;
      state.renderAdd = state.renderAdd + 1;
    }

    const AddIndispo = ref(null)
    const confirmDrawer = () => {
      (AddIndispo.value as any).handleCloseDrawer((AddIndispo.value as any).ValidConfirm);
    }

    function formatDate(date, adjustment = 0) {
      const day = date.getDate() + adjustment;
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    }

    onMounted(async () => {
      if (router.currentRoute.value.params.all) state.seeAll = router.currentRoute.value.params.all;
      setCalendarDatas();      
    })

    return {
      calendarOptions,
      state,
      setCalendarDatas,
      changeCalendarView,
      calendarRef,
      ValidConfirm,
      openEditDrawer,
      confirmDrawer,
      AddIndispo
    };
  }
});
